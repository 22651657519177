<template>
    <div class="Footer">
        <div class="container">
            <div class="left">
                <img
                    class="logo"
                    src="https://couragedotgg.s3.amazonaws.com/emblem.png"
                />
            </div>
            <div class="right">
                <ul class="nav">
                    <li v-scroll-to="'#about'">About</li>
                    <li v-scroll-to="'#merch'">Merch</li>
                    <a
                        class="social"
                        href="https://twitter.com/CouRageJD"
                        target="_blank"
                        ><img
                            src="https://couragedotgg.s3.amazonaws.com/twitter.svg"
                    /></a>
                    <a
                        href="https://www.instagram.com/couragejd/"
                        target="_blank"
                        class="social"
                        ><img
                            src="https://couragedotgg.s3.amazonaws.com/instagram.svg"
                    /></a>
                    <a
                        href="https://www.youtube.com/channel/UCrgTEmBuoObZiEXvZ6r3PQg"
                        target="_blank"
                        class="social"
                        ><img
                            src="https://couragedotgg.s3.amazonaws.com/youtube.svg"
                    /></a>
                    <a
                        href="https://www.tiktok.com/@couragejd"
                        target="_blank"
                        class="social"
                        ><img
                            src="https://couragedotgg.s3.amazonaws.com/tiktok.svg"
                    /></a>
                </ul>
                <p>
                    &copy; couragejd 2020 |
                    <a href="/privacy.html" target="_blank" class="footerLink"
                        >PRIVACY POLICY</a
                    >
                    |
                    <a href="/tos.html" target="_blank" class="footerLink"
                        >TERMS OF SERVICE</a
                    >
                </p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Footer",
    data() {
        return {};
    },
    components: {},
    props: {},
    mounted() {},
};
</script>

<style lang="scss" scoped>
.Footer {
    background-color: #ffc72c;
    padding: 30px;
    text-transform: uppercase;
    font-family: din-2014, sans-serif;
    font-weight: 600;
    .container {
        display: flex;
        align-items: center;
        @media screen and (max-width: 600px) {
            display: block;
            text-align: center;
        }
    }
    .logo {
        width: 100px;
        height: auto;
    }
    .right {
        flex-grow: 1;
        text-align: right;
        @media screen and (max-width: 600px) {
            padding-top: 40px;
            text-align: center;
        }
    }
    p {
        padding-top: 20px;
        font-size: 13px;
        color: rgba(0, 0, 0, 0.8);
    }
    .nav {
        li,
        a {
            display: inline-block;
            vertical-align: top;
            padding: 0 20px;
            font-size: 15px;
            cursor: pointer;
            transition: opacity 0.2s;
            text-decoration: none;
            color: inherit;
            @media screen and (max-width: 600px) {
                display: none;
            }
            &:hover {
                opacity: 0.6;
            }
            &:active {
                transform: translateY(1px);
            }
            img {
                height: 18px;
                width: auto;
            }
        }
    }

    .footerLink {
        color: inherit;
        text-decoration: none;

        &:active,
        &:hover,
        &:visited {
            color: inherit;
        }
    }
}
</style>
