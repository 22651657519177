<template>
    <div class="Merch" id="merch">
        <div class="left">
            <div class="box">
                <img v-if="activeShirt" :src="activeShirt.image" alt="" />
                <h4 v-if="activeShirt" class="merch-title">
                    {{ activeShirt.copy }}
                </h4>
                <ul class="dots" v-if="activeShirt">
                    <li
                        :class="{
                            'is-active': activeShirt.image === shirt.image,
                        }"
                        v-for="(shirt, index) in shirts"
                        :key="index"
                        @click="setActive(index)"
                    />
                </ul>
            </div>
        </div>
        <div class="right">
            <h2>
                Merch
                <h3>shop the latest styles <br />of the courageous</h3>
            </h2>
            <a href="https://shop.courage.gg"
                >See More
                <svg
                    style="width: 12px; height: auto; margin-left: 5px"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="#0b2240"
                >
                    <path d="M21 12l-18 12v-24z" /></svg
            ></a>
            <div>
                <img
                    class="outline"
                    src="https://couragedotgg.s3.amazonaws.com/courage-outline.png"
                    alt=""
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Merch",
    data() {
        return {
            shirts: [
                {
                    image: "https://couragedotgg.s3.amazonaws.com/11.png",
                    copy: "The Comfort Hoodie",
                },
                {
                    image: "https://couragedotgg.s3.amazonaws.com/22.png",
                    copy: "The Oversized Blue Tee",
                },
                {
                    image: "https://couragedotgg.s3.amazonaws.com/33.png",
                    copy: "The Comfort Crew",
                },
            ],
            activeShirt: null,
            timeout: null,
            waitTime: null,
            activeShirtIndex: null,
        };
    },
    methods: {
        setActive(index) {
            this.activeShirt = this.shirts[index];
            clearInterval(this.timeout);
            clearInterval(this.waitTime);

            this.waitTime = setTimeout(() => {
                this.startRotate(true);
            }, 7000);
        },
        startRotate(startEarly) {
            if (startEarly) {
                this.activeShirtIndex++;
                if (this.activeShirtIndex > this.shirts.length - 1) {
                    this.activeShirtIndex = 0;
                }
                this.activeShirt = this.shirts[this.activeShirtIndex];
            }

            this.timeout = setInterval(() => {
                this.activeShirtIndex++;
                if (this.activeShirtIndex > this.shirts.length - 1) {
                    this.activeShirtIndex = 0;
                }
                this.activeShirt = this.shirts[this.activeShirtIndex];
            }, 5000);
        },
    },
    mounted() {
        this.activeShirt = this.shirts[0];
        this.activeShirtIndex = 0;

        this.startRotate();
    },
};
</script>

<style lang="scss" scoped>
.Merch {
    padding-left: 120px;
    display: flex;
    padding-bottom: 60px;
    @media screen and (max-width: 1200px) {
        display: block;
        padding: 0 0px;
        max-width: 900px;
        margin: 0 auto;
    }
    .merch-title {
        padding-top: 20px;
        font-family: din-2014, sans-serif;
        font-weight: 600;
        text-transform: uppercase;
        margin-top: 0;
        padding-top: 10px;
        color: #777;
    }
    .dots {
        padding-top: 35px;
        li {
            display: inline-block;
            height: 10px;
            width: 10px;
            background-color: #dbdbdb;
            margin: 0 15px;
            cursor: pointer;
            transition: background-color 0.3s;
            &.is-active {
                background-color: #0b2240;
            }
            &:hover {
                background-color: #bbb;
            }
            &:active {
                transform: translateY(1px);
            }
        }
    }
    .outline {
        width: 800px;
        height: auto;
        margin-left: -580px;
        margin-top: 50px;
        pointer-events: none;
        @media screen and (max-width: 1200px) {
            display: block;
            margin: 50px auto;
            width: 90%;
        }
    }
    .left {
        flex-grow: 1;
    }
    .right {
        padding-right: 100px;
        padding-left: 20px;
        @media screen and (max-width: 1200px) {
            padding: 0;
        }
        h2 {
            text-transform: uppercase;
            font-size: 170px;
            font-weight: bold;
            line-height: 1;
            color: #0b2240;
            font-family: dharma-gothic-c, sans-serif;
            font-weight: 800;
            font-style: normal;
            text-align: right;
            @media screen and (max-width: 1200px) {
                text-align: center;
                font-size: 120px;
                padding-top: 40px;
            }
        }
        h3 {
            font-size: 14px;
            text-transform: uppercase;
            margin: 0;
            padding: 0;
            border-bottom: 24px solid #ffc72c;
            padding-bottom: 34px;
            font-family: din-2014, sans-serif;
            font-weight: 600;
            font-style: normal;
            width: 220px;
            font-size: 17px;
            padding-top: 10px;
            text-align: right;
            @media screen and (max-width: 1200px) {
                margin: 0 auto;
                text-align: center;
            }
        }
        a {
            display: block;
            line-height: 75px;
            background-color: #6ab3e8;
            max-width: 220px;
            text-align: center;
            font-family: din-2014, sans-serif;
            font-weight: 600;
            transition: background-color 0.2s;
            cursor: pointer;
            user-select: none;
            text-decoration: none;
            margin-top: 50px;
            color: #0b2240;
            padding-top: 5px;
            text-transform: uppercase;
            @media screen and (max-width: 1200px) {
                max-width: 600px;
                margin-left: auto;
                margin-right: auto;
            }
            &:hover {
                background-color: darken(#6ab3e8, 10);
            }
            &:active {
                transform: translateY(1px);
            }
        }
    }
    .box {
        background-color: #fff;
        max-width: 960px;
        margin-top: 140px;
        height: 650px;
        text-align: center;
        @media screen and (max-width: 1200px) {
            height: 520px;
        }
        @media screen and (max-width: 600px) {
            height: 320px;
        }
        img {
            height: 725px;
            width: auto;
            margin-top: -200px;
            @media screen and (max-width: 1200px) {
                height: 500px;
                width: auto;
                margin-top: -100px;
            }
            @media screen and (max-width: 600px) {
                height: 300px;
                width: auto;
                margin-top: -100px;
            }
        }
    }
}
</style>
