<template>
    <div class="Hero">
        <nav>
            <a href="/">
                <img
                    class="logo"
                    src="https://couragedotgg.s3.amazonaws.com/logo.png"
                    alt="Courage"
                />
            </a>
            <ul>
                <li v-scroll-to="'#about'">About</li>
                <a href="https://shop.courage.gg">Merch</a>
                <a
                    href="https://twitter.com/CouRageJD"
                    target="_blank"
                    class="social"
                    ><img
                        src="https://couragedotgg.s3.amazonaws.com/twitter.svg"
                /></a>
                <a
                    href="https://www.instagram.com/couragejd/"
                    target="_blank"
                    class="social"
                    ><img
                        src="https://couragedotgg.s3.amazonaws.com/instagram.svg"
                /></a>
                <a
                    href="https://www.youtube.com/channel/UCrgTEmBuoObZiEXvZ6r3PQg"
                    target="_blank"
                    class="social"
                    ><img
                        src="https://couragedotgg.s3.amazonaws.com/youtube.svg"
                /></a>
                <a
                    href="https://www.tiktok.com/@couragejd"
                    target="_blank"
                    class="social"
                    ><img
                        src="https://couragedotgg.s3.amazonaws.com/tiktok.svg"
                /></a>
                <a
                    class="watch"
                    href="https://www.youtube.com/channel/UCrgTEmBuoObZiEXvZ6r3PQg"
                    target="_blank"
                >
                    Watch
                    <svg
                        style="width: 12px; height: auto; margin-left: 5px"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                    >
                        <path d="M21 12l-18 12v-24z" />
                    </svg>
                </a>
            </ul>
        </nav>
        <video
            v-if="showVideo"
            autoplay
            playsinline
            muted
            id="video"
            style="width: 100%; margin-bottom: 100px"
        >
            <source
                src="https://couragedotgg.s3.amazonaws.com/header.mp4"
                type="video/mp4"
            />
        </video>
        <div class="banner" v-else>
            <h1>
                <div class="one">Jack</div>
                <div class="two">Courage</div>
                <div class="three">Dunlop</div>
                <div class="four">
                    <span
                        >Take Part
                        <hr /></span
                    >. Take Over.
                </div>
            </h1>
            <img
                class="jack"
                src="https://couragedotgg.s3.amazonaws.com/jack.png"
                alt=""
            />
        </div>
        <div class="about" id="about">
            <h2>
                About
                <h3>Courage</h3>
            </h2>
            <p>
                A larger than life personality, Jack "CouRageJD" Dunlop burst
                onto the scene in 2016 and quickly established himself as one of
                the most popular streamers online, even earning a shoutout from
                one of his favorite celebrities, pop superstar Ariana Grande.
                CouRage got his start as a high-energy shoutcaster for
                competitive Call of Duty and Fortnite, but these days you'll
                just as likely find him playing whatever the latest games are on
                YouTube and creating content for 100 Thieves.
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: "Hero",
    data() {
        return {
            showVideo: true,
        };
    },
    components: {},
    props: {},
    mounted() {
        // const VIDEO = document.getElementById('video')
        /* VIDEO.onended = () => {
      this.showVideo = false
    } */
    },
};
</script>

<style lang="scss" scoped>
.logo {
    height: 70px;
    width: auto;
    display: inline-block;
    vertical-align: middle;
    @media screen and (max-width: 980px) {
        width: 65%;
        padding-bottom: 14px;
        height: auto;
        max-width: 300px;
        margin-top: 20px;
    }
}

nav {
    display: block;
    padding-left: 30px;
    line-height: 80px;
    @media screen and (max-width: 980px) {
        text-align: center;
        padding-bottom: 30px;
    }
    @media screen and (max-width: 965px) {
        padding-left: 0;
        padding-bottom: 0;
    }
    ul {
        padding: 0;
        margin: 0;
        background-color: #fff;
        float: right;
        padding-left: 30px;
        @media screen and (max-width: 980px) {
            display: none;
        }
        li,
        a {
            display: inline-block;
            vertical-align: top;
            padding: 0 30px;
            margin: 0;
            text-transform: uppercase;
            font-family: din-2014, sans-serif;
            font-weight: 600;
            transition: background-color 0.2s;
            cursor: pointer;
            background-color: rgba(#fdc52c, 0);
            user-select: none;
            text-decoration: none;
            color: #000;
            &:hover {
                background-color: #fdc52c;
            }
            &:active {
                transform: translateY(1px);
            }
            &.social {
                padding: 0 30px;
                img {
                    height: 20px;
                    width: auto;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
            &.watch {
                background-color: #6ab3e8;
                &:hover {
                    background-color: darken(#6ab3e8, 10);
                }
            }
        }
    }
}

.banner {
    padding-top: 20px;
    padding-left: 60px;
    background-image: url(https://couragedotgg.s3.amazonaws.com/hero-bg.png);
    background-repeat: no-repeat;
    background-position: center 50px;
    background-size: auto 95%;
    user-select: none;
    @media screen and (max-width: 965px) {
        padding: 0;
        background-size: 100% auto;
        background-position: center;
        nav {
            margin: 0;
            padding: 0;
        }
    }
    h1 {
        font-family: dharma-gothic-c, sans-serif;
        font-weight: 800;
        font-style: normal;
        color: #0b2240;
        text-transform: uppercase;
        float: right;
        max-width: 780px;
        width: 100%;
        margin-left: -100%;
        text-align: center;
        font-size: 160px;
        line-height: 180px;
        font-family: dharma-gothic-c, sans-serif;
        font-weight: 800;
        font-style: normal;
        margin-top: 100px;
        margin-right: 80px;
        @media screen and (max-width: 1550px) {
            font-size: 120px;
            line-height: 130px;
            max-width: 40%;
        }
        @media screen and (max-width: 1200px) {
            font-size: 80px;
            line-height: 100px;
        }
        @media screen and (max-width: 965px) {
            margin: 0;
            float: none;
            padding: 0;
            width: 100%;
            max-width: 100%;
            text-align: center;
        }
        .one {
            text-align: left;
            color: #69b3e7;
            @media screen and (max-width: 965px) {
                display: none;
            }
        }
        .two {
            text-align: center;
            font-size: 400px;
            line-height: 240px;
            padding-top: 10px;
            @media screen and (max-width: 1550px) {
                font-size: 350px;
                line-height: 250px;
            }
            @media screen and (max-width: 1200px) {
                font-size: 250px;
                line-height: 150px;
            }
            @media screen and (max-width: 965px) {
                font-size: 175px;
                line-height: 100px;
                display: none;
            }
        }
        .three {
            text-align: right;
            color: #69b3e7;
            @media screen and (max-width: 965px) {
                display: none;
            }
        }
        .four {
            text-align: right;
            font-size: 85px;
            letter-spacing: 0.085em;
            padding-right: 4%;
            margin-top: -50px;
            @media screen and (max-width: 1550px) {
                font-size: 60px;
            }
            @media screen and (max-width: 1200px) {
                font-size: 40px;
                margin-top: -40px;
            }
            @media screen and (max-width: 965px) {
                padding: 0;
                padding: 0;
                display: inline-block;
                vertical-align: top;
                margin-top: 0;
            }
            span {
                display: inline-block;
                vertical-align: top;
            }
            hr {
                display: block;
                box-shadow: 0 0 0 4px #fdc52c;
                border: none;
                height: 0px;
                margin: 0;
                padding: 0;
                margin-top: -1.1em;
                position: relative;
                @media screen and (max-width: 1200px) {
                    box-shadow: 0 0 0 2px #fdc52c;
                    margin-top: -1.25em;
                }
            }
        }
    }
}

.about {
    padding-left: 70px;
    position: relative;
    top: -150px;
    z-index: 20;
    margin: 0 150px;
    @media screen and (max-width: 1200px) {
        padding: 0;
        margin-right: 50px;
    }
    @media screen and (max-width: 965px) {
        margin: 0 0px;
        top: -304px;
        margin-bottom: -240px;
    }
    h2 {
        text-transform: uppercase;
        font-size: 170px;
        font-weight: bold;
        line-height: 1;
        color: #0b2240;
        font-family: dharma-gothic-c, sans-serif;
        font-weight: 800;
        font-style: normal;
        position: absolute;
        transform: translate(-100px, 120px);
        @media screen and (max-width: 965px) {
            transform: translate(0, 250px);
            display: block;
            position: static;
            text-align: center;
            font-size: 120px;
            max-width: 200px;
            margin: 0 auto;
        }
    }
    h3 {
        font-size: 14px;
        text-transform: uppercase;
        margin: 0;
        padding: 0;
        border-bottom: 24px solid #ffc72c;
        padding-bottom: 70px;
        font-family: din-2014, sans-serif;
        font-weight: 600;
        font-style: normal;
        width: 220px;
        margin-right: 100px;
        font-size: 17px;
        margin-top: -20px;
        position: relative;
        @media screen and (max-width: 965px) {
            position: static;
            margin: 0;
            width: auto;
            padding-bottom: 40px;
        }
    }
    p {
        font-size: 15px;
        background-color: #fff;
        margin: 0 auto;
        padding: 120px 120px 120px 240px;
        line-height: 40px;
        text-transform: uppercase;
        font-family: din-2014, sans-serif;
        font-weight: 600;
        font-style: normal;
        color: #0b2240;
        @media screen and (max-width: 965px) {
            padding: 80px;
            text-align: center;
            padding-top: 300px;
            line-height: 30px;
        }
        @media screen and (max-width: 600px) {
            padding-left: 30px;
            padding-right: 30px;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            padding-bottom: 50px;
        }
    }
}

.jack {
    position: relative;
    z-index: 10;
    width: 60%;
    height: auto;
    @media screen and (max-width: 965px) {
        width: 90%;
        max-width: 600px;
        display: block;
        margin: 0 auto;
    }
}
</style>
