<template>
    <div class="Content">
        <h2 class="title">
            Latest <br />
            Content
            <h3>&nbsp;</h3>
        </h2>
        <a
            class="video"
            v-for="video in videos"
            :key="video.id.videoId"
            :href="'https://www.youtube.com/watch?v=' + video.id.videoId"
            :style="{
                backgroundImage:
                    'url(https://img.youtube.com/vi/' +
                    video.id.videoId +
                    '/maxresdefault.jpg)',
            }"
        >
            <h3>{{ video.snippet.title }}</h3>
            <img src="https://couragedotgg.s3.amazonaws.com/play.png" alt="" />
        </a>
        <a
            class="more"
            href="https://www.youtube.com/channel/UCrgTEmBuoObZiEXvZ6r3PQg"
            >See More
            <svg
                style="width: 12px; height: auto; margin-left: 5px"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="#0b2240"
            >
                <path d="M21 12l-18 12v-24z" /></svg
        ></a>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "Content",
    data() {
        return {
            videos: null,
        };
    },
    components: {},
    props: {},
    mounted() {
        const endpoint =
            window.location.hostname === "localhost"
                ? "http://courageapi.localhost"
                : "https://api.courage.gg";

        axios.get(endpoint).then((response) => {
            this.videos = response.data;
        });
    },
};
</script>

<style lang="scss" scoped>
.Content {
    padding-bottom: 60px;
    text-align: right;
    padding-right: 100px;
    padding-left: 150px;
    background-image: url(https://couragedotgg.s3.amazonaws.com/watermark.png);
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-position: left 200px;
    @media screen and (max-width: 1000px) {
        padding: 0 15px;
        text-align: center;
        padding-bottom: 50px;
        background-position: center;
    }
    .video {
        height: 500px;
        line-height: 500px;
        width: 900px;
        background-size: cover;
        margin-bottom: 25px;
        text-align: right;
        cursor: pointer;
        box-shadow: inset 0 0 0 0 #ffc72c;
        transition: box-shadow 0.15s;
        display: block;
        margin-left: auto;
        @media screen and (max-width: 1410px) {
            width: 700px;
            height: 400px;
            line-height: 400px;
        }
        @media screen and (max-width: 1200px) {
            width: 500px;
            height: 400px;
            line-height: 400px;
        }
        @media screen and (max-width: 1000px) {
            width: 100%;
            max-width: 800px;
            margin: 0 auto;
            margin-bottom: 30px;
        }

        @media screen and (max-width: 600px) {
            height: 300px;
            line-height: 300px;
        }
        &:hover {
            box-shadow: inset 0 0 0 6px #ffc72c;
        }
        &:active {
            transform: translateY(1px);
        }
        h3 {
            background-color: rgba(0, 0, 0, 0.7);
            display: inline-block;
            padding: 10px;
            color: #fff;
            font-family: din-2014, sans-serif;
            font-weight: 600;
            max-width: 50%;
            line-height: 20px;
            vertical-align: top;
            float: left;
            text-align: left;
            margin-bottom: -1000px;
        }
        img {
            display: inline-block;
            vertical-align: bottom;
        }
    }
    .more {
        display: block;
        line-height: 75px;
        background-color: #6ab3e8;
        max-width: 220px;
        text-align: center;
        font-family: din-2014, sans-serif;
        font-weight: 600;
        transition: background-color 0.2s;
        cursor: pointer;
        user-select: none;
        text-decoration: none;
        margin-top: 50px;
        color: #0b2240;
        padding-top: 3px;
        text-transform: uppercase;
        margin-left: auto;
        width: auto;
        @media screen and (max-width: 1000px) {
            max-width: 100%;
        }
        &:hover {
            background-color: darken(#6ab3e8, 10);
        }
        &:active {
            transform: translateY(1px);
        }
    }
    .title {
        text-transform: uppercase;
        font-size: 170px;
        font-weight: bold;
        line-height: 0.9;
        color: #0b2240;
        font-family: dharma-gothic-c, sans-serif;
        font-weight: 800;
        font-style: normal;
        float: left;
        text-align: left;
        @media screen and (max-width: 1200px) {
            font-size: 120px;
        }
        @media screen and (max-width: 1000px) {
            display: block;
            float: none;
            text-align: center;
            padding-bottom: 40px;
        }
        h3 {
            font-size: 14px;
            text-transform: uppercase;
            margin: 0;
            padding: 0;
            border-bottom: 24px solid #ffc72c;
            padding-bottom: 34px;
            font-family: din-2014, sans-serif;
            font-weight: 600;
            font-style: normal;
            width: 220px;
            font-size: 17px;
            padding-top: 10px;
            text-align: right;
            margin: 0 auto;
            @media screen and (max-width: 1200px) {
                width: 180px;
            }
        }
    }
}
</style>
